.navBarCustom {
  display: flex;
  width: 100%;
  height: fit-content;
  padding: 26px 144px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background: #F5F7FA;
  box-shadow: 0px 281px 79px 0px rgba(0, 0, 0, 0.00), 0px 180px 72px 0px rgba(0, 0, 0, 0.01), 0px 101px 61px 0px rgba(0, 0, 0, 0.02), 0px 45px 45px 0px rgba(0, 0, 0, 0.03), 0px 11px 25px 0px rgba(0, 0, 0, 0.04);
}

header {
  position: fixed;
  width: 100%;
  z-index: 1000;
  background: var(--Neutral-Silver, #F5F7FA);
  box-shadow: 0px 281px 79px 0px rgba(0, 0, 0, 0.00), 0px 180px 72px 0px rgba(0, 0, 0, 0.01), 0px 101px 61px 0px rgba(0, 0, 0, 0.02), 0px 45px 45px 0px rgba(0, 0, 0, 0.03), 0px 11px 25px 0px rgba(0, 0, 0, 0.04);
}

.caveat {
  font-family: "Caveat";
  color: #484848;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.775px;
}

.row.flex {
  align-items: stretch;
}

.team-h2 {
  color: #4D4D4D;
  text-align: center;
  font-family: "Inter";
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
  margin-top: 80px;
  margin-bottom: 60px;
}

.team-h2 span {
  color: #52D94E;
  font-family: "Inter";
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
}

.luciano_photo {
  position: absolute;
  bottom: 186px;
  right: 0;
  z-index: 1;
  height: auto;
  width: 305px;
}

.julieta_photo {
  position: absolute;
  top: 133px;
  left: 0;
  z-index: 1;
  height: auto;
  width: 255px;
}

.luciano_row {
  display: flex;
  justify-content: flex-end;
}

.row_team {
  /*   max-width: 600px; */
  max-width: 70%;
  margin: 0px auto;
  position: relative;
  z-index: 10;
}

.col_projects {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}

.proyectos {
  text-align: center;
}

.proyectos p {
  color: #717171;
  text-align: center;

  /* Body/Regular/Body 2 */
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 26px;
  /* 150% */
}

.proyectos-h2 {
  color: #4D4D4D;
  text-align: center;
  font-family: "Inter";
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px;

  /* 122.222% */
}

.lead {
  margin-top: 37px;
  margin-bottom: 49px;
  color: #484848;
  font-family: "Inter";
  font-size: 19.324px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.6;
  /* 117.857% */
}

.logo_footer {
  position: relative;
  display: flex;
  gap: 10.65px;
}

.corazon {
  color: #51D54D;
  font-family: "Inter";
  font-size: 19.324px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.775px;
  margin-right: 8px;
  /* 117.857% */
}

.wsp_button {
  display: flex;
  height: 56px;
  padding: 14px 36px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #52D94E;
  white-space: nowrap;
}

.wsp_button span {
  margin-left: 16px;
  color: #FFF;
  text-align: center;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  white-space: nowrap;
}

.cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 153px;
}

.section_first {
  background-color: #edfbec;
  padding-bottom: 152px;
}

.section_second {
  background-color: #a3eba1;
  /* padding-bottom: 152px; */
  /*   padding-top: 152px; */
  margin-top: -50px;
  position: relative;
}

.section_third {
  position: relative;
  padding-bottom: 118px;
}

.section_four {
  background-color: #edfbec;
  padding-top: 80px;
}

.section_five {
  background-color: #a3eba1;
  padding: 80px 0px;
}

.final-h2 {
  color: #484848;
  text-align: center;

  /* Heading/Headline 1 */
  font-family: "Inter";
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 66px;
  margin-bottom: 32px;
}

.final-h2 span {
  color: #FFF;

  /* Heading/Headline 1 */
  font-family: "Inter";
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 66px;
}

h1 {
  color: #484848;
  text-align: center;
  text-shadow: 0px 207px 58px rgba(0, 0, 0, 0.01);

  /* Heading/Headline 1 */
  font-family: "Inter";
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 66px;
  margin-top: 24.5px;
  /* 117.857% */
}

.h2ayudar {
  color: #FFF;
  font-family: "Inter";
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
  /* 122.222% */
}

.h3nombre {
  color: #373737;
  font-family: "Inter";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.h3nombre img {
  margin-right: 12px;
}

.h4sub {
  color: #4D4D4D;
  font-family: "Inter";
  font-size: 24px;
  font-style: normal;
  white-space: nowrap;
  font-weight: 500;
  line-height: 44px;
  /* 183.333% */
}

.pdesc {
  color: #4D4D4D;
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  /* 166.667% */
}

a {
  text-decoration: none;
}

.cont_col_blocks {
  width: 242px;
  display: flex;
  padding: 68px 21px 57px 21px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  position: relative;
  top: -23px;
  align-self: stretch;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 21.131px 21.131px 0px rgba(0, 0, 0, 0.09), 0px 5.283px 11.446px 0px rgba(0, 0, 0, 0.10);
}

.span1_block {
  color: #4D4D4D;
  text-align: center;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  /* 140% */
}

.span2_block {
  color: #52D94E;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  /* 140% */
}

.row_col_blocks {
  justify-content: space-evenly;
}

.pepino_overlapping {
  position: absolute;
  top: -36px;
  width: 70px;
  height: 70px;
}

.position_overlapping {
  /*   position: absolute;
  top: -23px;
  width: 100%; */
  /*  position: absolute;
  top: -23px;
  width: 100%; */
  /* top: calc(100% - 23px);
  position: absolute;
  z-index: 2; */
}

ul {
  list-style: none;
  padding: 0px;
}

li {
  color: #4E4E4E;
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
}

.ellipsis {
  margin-right: 12.85px;
}

footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  background-color: #263238;
}

.icon_cont {
  display: flex;
  gap: 18px;
}

.action_btn_cont {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.invert_hand {
  transform: scaleX(-1);
}

.ayudar_cont {
  margin-top: 80px;
  padding-bottom: 40px;
}

.loader_cont {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.ninthine {
  position: absolute;
  color: #52D94E;
  text-align: center;
  font-family: "Inter";
  font-size: 21.398px;
  font-style: normal;
  font-weight: 600;
  line-height: 25.22px;
  /* 117.857% */
}

.pepino_loader {
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 1098px) {


  .action_btn_cont img {
    display: none;
  }

  .julieta_row {
    margin-top: 295px !important;
  }

  .luciano_row {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    /* padding-top: 48px; */
  }

  .luciano_photo {
    bottom: 291px;
  }

  .row_team {
    width: 100%;
  }
}

@media (max-width: 992px) {
  .cont_col_blocks {
    margin-bottom: 59px;
  }

  .ayudar_cont {
    margin-top: 21px;
  }
}

@media (max-width: 860px) {
  .navBarCustom {
    display: flex;
    flex-direction: column;
  }

  .navBarCustom a {
    margin-bottom: 40px;
  }
}

@media (max-width: 405px) {
  .iconosvarios {
    width: 100%;
  }
}